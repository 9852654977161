var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"dialogForm"},[_c('v-card',{staticClass:"ma-1 pa-1 h-100"},[_c('v-card-title',{staticClass:"pa-1"},[_c('h4',[_vm._v(_vm._s(_vm.fileName))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","dense":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Saqlash ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push('/blank-templates/list')}}},[_vm._v(" Ortga ")])],1)],1),_c('v-card',{staticClass:"ma-1 pa-1"},[_c('div',[_c('v-system-bar',{attrs:{"window":"","color":"#eee"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("document_detail_attributes.index")))]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"success","large":""},on:{"click":function($event){_vm.blank_attribute_templates.push(
                    {
                      id: Date.now(),
                      blank_id: _vm.$route.params.id,
                      attribute_name: '',
                      parameter_name: '',
                      data_type_id: '',
                    }
                 )}}},[_vm._v("mdi-plus-circle ")])],1)],1),_c('v-card-text',_vm._l((_vm.blank_attribute_templates),function(item,index){return _c('v-row',{key:index,staticClass:"pa-1 mt-1",staticStyle:{"border":"1px solid black"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('div',[_c('v-system-bar',{attrs:{"window":"","color":"#edf5ff"}},[_c('v-spacer'),_c('v-icon',{attrs:{"color":"error","medium":""},on:{"click":function($event){return _vm.deleteBlankAttribute(item)}}},[_vm._v("mdi-delete ")])],1)],1)]),_c('v-col',{staticClass:"py-1 px-2",attrs:{"cols":"6","md":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("blankTemplate.attribute_name")))]),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || _vm.$t('input.required'); }],"hide-details":"","dense":"","outlined":""},model:{value:(item.attribute_name),callback:function ($$v) {_vm.$set(item, "attribute_name", $$v)},expression:"item.attribute_name"}})],1),_c('v-col',{staticClass:"py-1 px-2",attrs:{"cols":"6","md":"4"}},[(_vm.fileType === 0)?_c('label',[_vm._v(_vm._s(_vm.$t("blankTemplate.parameter_name")))]):_c('label',[_vm._v(_vm._s(_vm.$t("blankTemplate.cell_address")))]),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || _vm.$t('input.required'); }],"hide-details":"","dense":"","outlined":""},model:{value:(item.parameter_name),callback:function ($$v) {_vm.$set(item, "parameter_name", $$v)},expression:"item.parameter_name"}})],1),_c('v-col',{staticClass:"py-1 px-2",attrs:{"cols":"6","md":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("blankTemplate.data_type_id")))]),_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.dataTypes,"rules":[function (v) { return !!v || _vm.$t('input.required'); }],"hide-details":"","dense":"","item-text":"text","item-value":"value","outlined":"","full-width":""},model:{value:(item.data_type_id),callback:function ($$v) {_vm.$set(item, "data_type_id", $$v)},expression:"item.data_type_id"}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }